export default {
  props: {
    listKey: String,
    config: [Object, Boolean],
    name: String,
    row: Object,
  },
  methods: {
    onClick() {
      if(typeof this.config.callback != 'function') return
      this.config.callback(window.eagleLodash.cloneDeep(this.row))
    },
  },
  computed: {
    hasWritePermission() {
      return this.$store.getters[`list/${this.listKey}/hasWritePermission`]
    },
    create() {
      if(typeof this.config.create === 'function') {
        return this.config.create(this.row, this.hasWritePermission)
      }

      return true
    },
    listTargetKey() {
      return this.$store.getters[`list/${this.listKey}/targetKey`]
    },
    listApi() {
      return this.$store.getters[`list/${this.listKey}/api`]
    },
    listActions() {
      return this.$store.getters[`list/${this.listKey}/actions`]
    },
    color() {
      if(typeof this.config.color === 'function') {
        return this.config.color(this.row)
      }

      if(this.config.color) return this.config.color

      return 'primary'
    },
    buttonProperties() {
      if(typeof this.config.buttonProperties === 'function') {
        return this.config.buttonProperties(this.row)
      }

      return null
    },
    route() {
      if(typeof this.config.route === 'function') {
        return this.config.route(this.row)
      }

      return null
    },
    href() {
      if(typeof this.config.href === 'function') {
        return this.config.href(this.row)
      }

      return null
    },
    icon() {
      if(typeof this.config.icon === 'function') {
        return this.config.icon(this.row)
      }

      if(this.config.icon) return this.config.icon

      return null
    },
    linkTarget() {
      if(this.config.linkTarget === undefined) return '_blank'
      return this.config.linkTarget || '_blank'
    },
    label() {
      if(typeof this.config.label === 'function') {
        return this.config.label(this.row)
      }

      return this.config.label || null
    },
    disabled() {
      if (this.config.disabled == undefined) return false;
      if (this.config.disabled == true) return true;
      if (typeof this.config.disabled == "function") {
        return this.config.disabled(this.row);
      }
      return false;
    },
  },
}
